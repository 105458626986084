import React, { useState, useEffect } from 'react';
import '../ContactUsStyles.css';
import sanityClient from '../sanityClient';
import { Helmet } from 'react-helmet-async';
import { initializeAnalytics, trackPageView, trackEvent } from '../analytics';

const ContactUs = ({ isEnglish }) => {
  const [contactContent, setContactContent] = useState(null);

  useEffect(() => {
    // Initialize Google Analytics
    initializeAnalytics('G-M8CTMCVQ5P');

    // Track page view
    trackPageView('/contact-us');

    // Fetch content from Sanity
    sanityClient
      .fetch(
        `*[_type == "contactUs"][0]{
          title,
          title_jp,
          description,
          description_jp,
          email,
          email_jp,
          image{
            asset->{
              url
            }
          }
        }`
      )
      .then((data) => setContactContent(data))
      .catch((err) => console.error('Error fetching contact us content:', err));
  }, []);

  useEffect(() => {
    // Track user interaction duration
    const startTime = Date.now();

    return () => {
      const duration = Math.floor((Date.now() - startTime) / 1000); // In seconds
      trackEvent('User Engagement', 'Page Duration', `Contact Us: ${duration}s`);
    };
  }, []);

  if (!contactContent) return <div></div>;

  // Determine if the text should be in English or Japanese
  const title = isEnglish ? contactContent.title : contactContent.title_jp;
  const description = isEnglish ? contactContent.description : contactContent.description_jp;
  const email = isEnglish ? contactContent.email : contactContent.email_jp;

  return (
    <section className="contact-us-section">
      <Helmet>
        <title>Myosuki - Home</title>
        <meta name="description" content="Welcome to Myosuki. Explore our content, news, and team." />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className="contact-us-container">
        {/* Conditionally render the image only if it exists */}
        {contactContent.image && contactContent.image.asset && (
          <div className="contact-us-image">
            <img src={contactContent.image.asset.url} alt="Contact Us" />
          </div>
        )}

        {/* Display the text content */}
        <div className="contact-us-text">
          <h1>{title}</h1>
          <h3>{email}</h3>
          <p>{description}</p>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
