import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import sanityClient from '../sanityClient';
import { initializeAnalytics, trackEvent } from '../analytics'; 
import '../NewsSectionStyles.css';

const NewsSection = ({ isEnglish }) => {
  const [newsPosts, setNewsPosts] = useState([]);
  const [newsLimit, setNewsLimit] = useState(2); // Default limit

  useEffect(() => {
    // Initialize Google Analytics
    initializeAnalytics('G-M8CTMCVQ5P');

    // Fetch the site config to get the news limit
    sanityClient
      .fetch(`*[_type == "siteConfig"][0]{ newsLimit }`)
      .then((config) => {
        if (config && config.newsLimit) {
          setNewsLimit(config.newsLimit);
        } else {
          setNewsLimit(2); // Fallback to default limit
        }
      })
      .catch(console.error);

    // Fetch news posts and filter by those that should show on the homepage
    sanityClient
      .fetch(
        `*[_type == "newsPost" && showOnHomepage == true]{
          title,
          title_jp,
          shortDescription,
          shortDescription_jp,
          date,
          slug,
          image{
            asset->{
              url
            }
          }
        }`
      )
      .then((data) => setNewsPosts(data))
      .catch(console.error);
  }, []);

  const handleFeaturedArticleClick = (title) => {
    trackEvent('Featured Article Click', 'Click', title);
  };

  return (
    <section className="news-section">
      <Helmet>
        <title>Latest News - Myosuki</title>
        <meta name="description" content="Stay updated with the latest news and updates from Myosuki." />
        <meta name="robots" content="index, follow" />
      </Helmet>

      {newsPosts.slice(0, newsLimit).map((post, index) => (
        <div key={index} className="news-item">
          <Link
            to={`/news/${post.slug.current}`}
            className="news-link"
            onClick={() => handleFeaturedArticleClick(isEnglish ? post.title : post.title_jp)}
          >
            <img src={post.image.asset.url} alt={isEnglish ? post.title : post.title_jp} />
            <div className="news-content">
              <p className="date">{new Date(post.date).toLocaleDateString()}</p>
              <h2>{isEnglish ? post.title : post.title_jp}</h2>
              <p>{isEnglish ? post.shortDescription : post.shortDescription_jp}</p>
            </div>
          </Link>
        </div>
      ))}

      <div className="read-more-container">
        <Link to="/news" className="read-more-button">Read More Blog Posts</Link>
      </div>
    </section>
  );
};

export default NewsSection;
