// src/analytics.js

// Initialize Google Analytics
export const initializeGA = () => {
    if (!window.gtag) {
      console.warn('Google Analytics not loaded!');
      return;
    }
    window.gtag('js', new Date());
    window.gtag('config', 'G-M8CTMCVQ5P'); // Replace with GA tracking ID
  };
  
  // Alias for initializeGA
  export const initializeAnalytics = initializeGA;
  
  // Track custom events
  export const trackEvent = (action, category, label, value) => {
    if (!window.gtag) {
      console.warn(`Google Analytics not loaded! Event '${action}' not sent.`);
      return;
    }
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  };
  
  // Track page views
  export const trackPageView = (url) => {
    if (!window.gtag) {
      console.warn('Google Analytics not loaded! Page view not tracked.');
      return;
    }
    window.gtag('config', 'G-M8CTMCVQ5P', {
      page_path: url,
    });
  };
  