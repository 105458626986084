import React, { useState, useEffect } from 'react';
import '../AboutUsStyles.css';
import sanityClient from '../sanityClient';
import { Helmet } from 'react-helmet-async';
import { FaTwitter, FaDiscord, FaEnvelope, FaYoutube } from 'react-icons/fa';
import { initializeAnalytics, trackPageView, trackEvent } from '../analytics';

const AboutUs = ({ isEnglish }) => {
  const [aboutUsContent, setAboutUsContent] = useState(null);

  useEffect(() => {
    // Initialize GA with tracking ID
    initializeAnalytics('G-M8CTMCVQ5P');

    // Track page view
    trackPageView('/about-us');

    // Fetch content
    sanityClient
      .fetch(
        `*[_type == "aboutUs"][0]{
          title,
          title_jp,
          description,
          description_jp,
          mainContent,
          mainContent_jp,
          image{
            asset->{
              url
            }
          },
          teamMembers[] {
            name,
            role,
            role_jp,
            image{
              asset->{
                url
              }
            },
            socialLinks {
              discordId,
              email,
              twitter,
              youtube
            }
          },
          govtInfo
        }`
      )
      .then((data) => setAboutUsContent(data))
      .catch((err) => console.error('Error fetching about us content:', err));
  }, []);

  useEffect(() => {
    // Track user interaction duration
    const startTime = Date.now();

    return () => {
      const duration = Math.floor((Date.now() - startTime) / 1000); // In seconds
      trackEvent('User Engagement', 'Page Duration', `About Us: ${duration}s`);
    };
  }, []);

  const handleLinkClick = (label) => {
    trackEvent('Hyperlink Click', 'Click', label);
  };

  if (!aboutUsContent) return <div>Loading...</div>;

  const title = isEnglish ? aboutUsContent.title : aboutUsContent.title_jp;
  const description = isEnglish ? aboutUsContent.description : aboutUsContent.description_jp;
  const mainContent = isEnglish ? aboutUsContent.mainContent : aboutUsContent.mainContent_jp;

  const director = aboutUsContent.teamMembers.find(
    (member) => member.role === 'Studio Director'
  );
  const otherTeamMembers = aboutUsContent.teamMembers.filter(
    (member) => member.role !== 'Studio Director'
  );

  return (
    <section className="about-us-section">
      <Helmet>
        <title>Myosuki - Home</title>
        <meta name="description" content="Welcome to Myosuki. Explore our content, news, and team." />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className="about-us-container">
        <div className="about-us-image">
          <img src={aboutUsContent.image.asset.url} alt="About Us" />
        </div>
        <div className="about-us-text">
          <h1>{title}</h1>
          <h3>{description}</h3>
          <p>{mainContent}</p>
          {!isEnglish && aboutUsContent.govtInfo && (
            <div className="govt-info">
              <h4>Government Information</h4>
              <p>{aboutUsContent.govtInfo}</p>
            </div>
          )}
        </div>
      </div>

      <div className="team-section">
        <h2>Our Team</h2>
        {director && (
          <div className="director">
            <div className="team-member">
              <div className="member-image">
                <img src={director.image.asset.url} alt={director.name} />
              </div>
              <h3 className="member-name">{director.name}</h3>
              <p className="member-role">{isEnglish ? director.role : director.role_jp}</p>
              <div className="member-social-icons">
                {director.socialLinks?.twitter && (
                  <a
                    href={director.socialLinks.twitter}
                    onClick={() => handleLinkClick('Twitter')}
                  >
                    <FaTwitter />
                  </a>
                )}
                {director.socialLinks?.discordId && (
                  <a
                    href={`https://discordapp.com/users/${director.socialLinks.discordId}`}
                    onClick={() => handleLinkClick('Discord')}
                  >
                    <FaDiscord />
                  </a>
                )}
                {director.socialLinks?.email && (
                  <a
                    href={`mailto:${director.socialLinks.email}`}
                    onClick={() => handleLinkClick('Email')}
                  >
                    <FaEnvelope />
                  </a>
                )}
                {director.socialLinks?.youtube && (
                  <a
                    href={director.socialLinks.youtube}
                    onClick={() => handleLinkClick('YouTube')}
                  >
                    <FaYoutube />
                  </a>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="team-container">
          {otherTeamMembers.map((member, index) => (
            <div className="team-member" key={index}>
              <div className="member-image">
                <img src={member.image.asset.url} alt={member.name} />
              </div>
              <h3 className="member-name">{member.name}</h3>
              <p className="member-role">{isEnglish ? member.role : member.role_jp}</p>
              <div className="member-social-icons">
                {member.socialLinks?.twitter && (
                  <a
                    href={member.socialLinks.twitter}
                    onClick={() => handleLinkClick(`Twitter: ${member.name}`)}
                  >
                    <FaTwitter />
                  </a>
                )}
                {member.socialLinks?.discordId && (
                  <a
                    href={`https://discordapp.com/users/${member.socialLinks.discordId}`}
                    onClick={() => handleLinkClick(`Discord: ${member.name}`)}
                  >
                    <FaDiscord />
                  </a>
                )}
                {member.socialLinks?.email && (
                  <a
                    href={`mailto:${member.socialLinks.email}`}
                    onClick={() => handleLinkClick(`Email: ${member.name}`)}
                  >
                    <FaEnvelope />
                  </a>
                )}
                {member.socialLinks?.youtube && (
                  <a
                    href={member.socialLinks.youtube}
                    onClick={() => handleLinkClick(`YouTube: ${member.name}`)}
                  >
                    <FaYoutube />
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
