import React, { useState, useEffect } from 'react'; 
import { useParams, useLocation } from 'react-router-dom'; 
import sanityClient from '../sanityClient'; 
import { PortableText } from '@portabletext/react'; 
import { Helmet } from 'react-helmet-async'; 
import { initializeAnalytics, trackEvent } from '../analytics'; 
import '../NewsPostStyles.css'; 

const NewsPost = ({ isEnglish }) => {
  const { slug } = useParams(); // Get the slug from the URL
  const location = useLocation(); // Get the current location (for preview query)
  const [post, setPost] = useState(null); // State to hold the fetched post data

  // Check if preview mode is enabled
  const isPreview = location.search.includes('preview=true');

  // Initialize Google Analytics
  useEffect(() => {
    initializeAnalytics('G-M8CTMCVQ5P'); 
  }, []);

  // Fetch post data with visibility logic
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "newsPost" && slug.current == $slug ${
          isPreview ? '' : '&& isVisible == true'
        }]{
          title,
          title_jp,
          date,
          time,
          isVisible,
          mainContent,
          mainContent_jp,
          image{
            asset->{
              url
            }
          }
        }`,
        { slug }
      )
      .then((data) => setPost(data[0]))
      .catch(console.error);
  }, [slug, isPreview]);

  // Track time spent reading the post
  useEffect(() => {
    const startTime = Date.now();

    return () => {
      const duration = Math.floor((Date.now() - startTime) / 1000); // In seconds
      if (post) {
        trackEvent('Post Engagement', 'Time Spent', `${post.title}: ${duration}s`);
      }
    };
  }, [post]);

  if (!post) {
    return <div></div>;
  }

  if (!isPreview && !post.isVisible) {
    return <div>This post is not yet public.</div>;
  }

  // Determine which language content to show
  const title = isEnglish ? post.title : post.title_jp;
  const mainContent = isEnglish ? post.mainContent : post.mainContent_jp;

  // Format date and time for display
  const formattedDate = new Date(post.date).toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const formattedTime = post.time
    ? new Date(`${post.date}T${post.time}`).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })
    : null;

  return (
    <>
      <Helmet>
        <title>{title} - Myosuki News</title>
        <meta name="description" content={`Read the latest updates: ${title}`} />
        <meta name="robots" content="index, follow" />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            "headline": title,
            "image": post.image.asset.url,
            "datePublished": new Date(post.date).toISOString(),
            "author": {
              "@type": "Person",
              "name": "Myosuki Team",
            },
            "publisher": {
              "@type": "Organization",
              "name": "Myosuki",
              "logo": {
                "@type": "ImageObject",
                "url": "https://myosuki.com/Resources/Buttons%20&%20Icons/myosuki-favicon/favicon.png",
              },
            },
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": `https://myosuki.com/news/${slug}`,
            },
          })}
        </script>
      </Helmet>

      <article className="news-post">
        <img src={post.image.asset.url} alt={title} className="news-post-image" />
        <h1>{title}</h1>
        <p>
          {formattedDate}
          {formattedTime && ` at ${formattedTime}`}
        </p>

        <div className="news-post-content">
          <PortableText value={mainContent} />
        </div>
      </article>
    </>
  );
};

export default NewsPost;
