import React, { useEffect, useState } from 'react';
import sanityClient from '../sanityClient';
import '../GamesStyles.css';
import { initializeAnalytics, trackPageView, trackEvent } from '../analytics'; 

const Games = ({ isEnglish }) => {
  const [games, setGames] = useState([]);

  useEffect(() => {
    // Initialize Google Analytics
    initializeAnalytics('G-M8CTMCVQ5P'); // Replace with GA Tracking ID

    // Track page view
    trackPageView('/games');

    // Fetch games data
    sanityClient
      .fetch(
        `*[_type == "game"]{
          title,
          title_jp,
          releaseDate,
          releaseDate_jp,
          description,
          description_jp,
          "imageUrl": image.asset->url,
          "logoUrl": logo.asset->url,
          steamLink,
          learnMoreLink
        }`
      )
      .then((data) => setGames(data))
      .catch((error) => console.error('Error fetching games:', error));
  }, []);  

  useEffect(() => {
    // Track time spent on the page
    const startTime = Date.now();

    return () => {
      const duration = Math.floor((Date.now() - startTime) / 1000); // In seconds
      trackEvent('User Engagement', 'Page Duration', `Games Page: ${duration}s`);
    };
  }, []);

  const handleGameClick = (gameTitle) => {
    trackEvent('Game Click', 'Click', gameTitle);
  };

  const handleButtonClick = (buttonType, gameTitle) => {
    trackEvent('Button Click', 'Click', `${buttonType}: ${gameTitle}`);
  };

  return (
    <section className="games-section">
      {games.map((game, index) => (
        <div className="game-card" key={index}>
          <div
            className="game-image"
            onClick={() => handleGameClick(isEnglish ? game.title : game.title_jp)}
          >
            <img src={game.imageUrl} alt={game.title} />
          </div>
          <div className="game-content">
            <img
              src={game.logoUrl}
              alt={`${isEnglish ? game.title : game.title_jp} Logo`}
              className="game-logo"
              onClick={() => handleGameClick(isEnglish ? game.title : game.title_jp)}
            />
            <h3 className="release-date">{isEnglish ? game.releaseDate : game.releaseDate_jp}</h3>
            <p className="description">{isEnglish ? game.description : game.description_jp}</p>
            <div className="game-buttons">
              <a
                href={game.steamLink}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-steam"
                onClick={() => handleButtonClick('Wishlist on Steam', isEnglish ? game.title : game.title_jp)}
              >
                {isEnglish ? 'Wishlist on Steam' : 'Steamのウィッシュリスト'}
              </a>
              <a
                href={game.learnMoreLink}
                className="btn btn-learn"
                onClick={() => handleButtonClick('Learn More', isEnglish ? game.title : game.title_jp)}
              >
                {isEnglish ? 'Learn more' : 'もっと詳しく'}
              </a>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Games;
