import React, { useEffect, useState } from 'react';
import sanityClient from '../sanityClient';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { initializeAnalytics, trackPageView, trackEvent } from '../analytics'; 
import '../NewsPageStyles.css';

const NewsPage = ({ isEnglish }) => {
  const [newsPosts, setNewsPosts] = useState([]);

  useEffect(() => {
    // Initialize Google Analytics
    initializeAnalytics('G-M8CTMCVQ5P');

    // Track page view
    trackPageView('/news');

    // Fetch the news posts from Sanity
    sanityClient
      .fetch(
        `*[_type == "newsPost"] | order(date desc){
          title,
          title_jp,
          date,
          slug,
          shortDescription,
          shortDescription_jp,
          image{
            asset->{
              url
            }
          }
        }`
      )
      .then((data) => setNewsPosts(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    // Track time spent on the page
    const startTime = Date.now();

    return () => {
      const duration = Math.floor((Date.now() - startTime) / 1000); // In seconds
      trackEvent('User Engagement', 'Page Duration', `News Page: ${duration}s`);
    };
  }, []);

  const handleArticleClick = (title) => {
    trackEvent('Article Click', 'Click', title);
  };

  if (!newsPosts.length) {
    return <div></div>;
  }

  return (
    <>
      <Helmet>
        <title>News - Myosuki</title>
        <meta name="description" content="Stay updated with the latest news from Myosuki." />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <section className="news-page">
        <h1 className="news-title">{isEnglish ? 'News' : 'ニュース'}</h1>
        <div className="news-list">
          {newsPosts.map((post, index) => (
            <Link
              key={index}
              to={`/news/${post.slug.current}`}
              className="news-item"
              onClick={() => handleArticleClick(isEnglish ? post.title : post.title_jp)}
            >
              <img
                src={post.image.asset.url}
                alt={isEnglish ? post.title : post.title_jp}
                className="news-image"
              />
              <div className="news-content">
                <p className="news-date">{new Date(post.date).toLocaleDateString()}</p>
                <h2 className="news-heading">{isEnglish ? post.title : post.title_jp}</h2>
                <p className="news-summary">
                  {isEnglish ? post.shortDescription : post.shortDescription_jp}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </section>
    </>
  );
};

export default NewsPage;
