import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import '../PolicyStyles.css';
import sanityClient from '../sanityClient';
import { PortableText } from '@portabletext/react';
import { initializeAnalytics, trackEvent } from '../analytics';

const Policy = ({ isEnglish }) => {
  const [policyContent, setPolicyContent] = useState(null);
  const [activeSection, setActiveSection] = useState(0); // Track active section

  useEffect(() => {
    // Initialize Google Analytics
    initializeAnalytics('G-M8CTMCVQ5P'); // Replace with GA Tracking ID

    // Fetch policy data
    sanityClient
      .fetch(
        `*[_type == "policy"][0]{
          title,
          title_jp,
          sections[] {
            title,
            title_jp,
            content,
            content_jp
          },
          image {
            asset->{
              url
            }
          }
        }`
      )
      .then((data) => setPolicyContent(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    // Track time spent on the page
    const startTime = Date.now();

    return () => {
      const duration = Math.floor((Date.now() - startTime) / 1000); // In seconds
      trackEvent('Page Engagement', 'Time Spent', `Policy Page: ${duration}s`);
    };
  }, []);

  const handleSectionChange = (index) => {
    setActiveSection(index);
    trackEvent('Policy Section Click', 'Click', isEnglish ? sections[index].title : sections[index].title_jp);
  };

  if (!policyContent) return <div>Loading...</div>;

  const title = isEnglish ? policyContent.title : policyContent.title_jp;
  const sections = policyContent.sections || [];

  return (
    <>
      <Helmet>
        <title>Policy - Myosuki</title>
        <meta name="description" content="Read our policies, including privacy, IP guidelines, and more." />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <section className="policy-section">
        <div className="policy-container">
          {policyContent.image && (
            <div className="policy-image">
              <img src={policyContent.image.asset.url} alt="Policy" />
            </div>
          )}

          <div className="policy-text">
            <h1>{title}</h1>

            <nav className="policy-menu">
              <ul>
                {sections.map((section, index) => (
                  <li key={index} className={activeSection === index ? 'active' : ''}>
                    <button
                      style={{
                        padding: isEnglish ? '8px 15px' : '10px 20px', // Adjust padding for JP text
                        fontSize: isEnglish ? '16px' : '18px', // Slightly larger font for JP
                      }}
                      onClick={() => handleSectionChange(index)}
                    >
                      {isEnglish ? section.title : section.title_jp}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>

            <div className="policy-content">
              <PortableText value={isEnglish ? sections[activeSection].content : sections[activeSection].content_jp} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Policy;
