import React from 'react';
import '../styles.css';
import { Helmet } from 'react-helmet-async';
import backgroundImage from '../assets/transition_pattern_small.png';
import { FaTwitter, FaDiscord, FaFacebook, FaYoutube } from 'react-icons/fa';
import { trackEvent } from '../analytics';

function Footer() {
  const handleLinkClick = (platform) => {
    trackEvent('Social Media Click', 'Click', platform);
  };

  return (
    <>
      {/* Helmet for SEO and indexing control */}
      <Helmet>
        <title>Footer - Myosuki</title>
        <meta name="description" content="Connect with Myosuki on social media platforms." />
        <meta name="robots" content="index, follow" />
      </Helmet>

      {/* Bubble Pattern Above the Footer */}
      <div
        className="dot-pattern"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '270px',
          width: '100%',
        }}
      ></div>

      <footer className="footer">
        {/* Footer content with social links */}
        <div className="footer-content">
          <div className="social-links">
            <a
              href="https://x.com/myosuki?lang=en"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick('Twitter')}
            >
              <FaTwitter className="social-icon" size={32} />
            </a>
            <a
              href="https://discord.gg/AKRNADN3"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick('Discord')}
            >
              <FaDiscord className="social-icon" size={32} />
            </a>
            <a
              href="https://www.facebook.com/myosuki/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick('Facebook')}
            >
              <FaFacebook className="social-icon" size={32} />
            </a>
            <a
              href="https://www.youtube.com/channel/UCukFu65QOtGP3hU8NZ3A5CQ"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick('YouTube')}
            >
              <FaYoutube className="social-icon" size={32} />
            </a>
          </div>
          <p style={{ margin: '10px 0 0' }}>©2024 MYOSUKI, LLC. ALL RIGHTS RESERVED</p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
