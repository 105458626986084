import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import sanityClient from '../sanityClient';
import '../PressKitStyles.css';
import { initializeAnalytics, trackEvent } from '../analytics'; 

const PressKit = ({ isEnglish }) => {
  const [pressKitData, setPressKitData] = useState(null);

  useEffect(() => {
    // Initialize Google Analytics
    initializeAnalytics('G-M8CTMCVQ5P');

    // Fetch press kit data from Sanity
    sanityClient
      .fetch(
        `*[_type == "pressKit"][0]{
          title,
          title_jp,
          description,
          description_jp,
          files[] {
            fileName,
            fileUrl
          },
          trailer
        }`
      )
      .then((data) => setPressKitData(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    // Track time spent on the page
    const startTime = Date.now();

    return () => {
      const duration = Math.floor((Date.now() - startTime) / 1000); // In seconds
      trackEvent('Page Engagement', 'Time Spent', `Press Kit Page: ${duration}s`);
    };
  }, []);

  const handleFileDownload = (fileName) => {
    trackEvent('File Download', 'Download', fileName);
  };

  const handleTrailerPlay = () => {
    trackEvent('Video View', 'Play', 'Press Kit Trailer');
  };

  if (!pressKitData) return <div></div>;

  const title = isEnglish ? pressKitData.title : pressKitData.title_jp;
  const description = isEnglish ? pressKitData.description : pressKitData.description_jp;

  return (
    <>
      <Helmet>
        <title>Press Kit - Myosuki</title>
        <meta name="description" content="Download Myosuki's press kit, trailers, and other media files." />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <section className="press-kit-section">
        <h1>{title}</h1>
        <p>{description}</p>

        {/* Trailer Section */}
        <h2>Trailers</h2>
        {pressKitData.trailer ? (
          <iframe
            width="100%"
            height="450px"
            src={`https://www.youtube.com/embed/${new URL(pressKitData.trailer).searchParams.get('v')}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            style={{ border: 'none' }}
            onPlay={handleTrailerPlay} // Track video play
          ></iframe>
        ) : (
          <p>No trailer available</p>
        )}

        {/* Files Section */}
        <h2>Files</h2>
        {pressKitData.files && pressKitData.files.length > 0 ? (
          <div className="file-list">
            {pressKitData.files.map((file, index) => (
              <a
                key={index}
                href={file.fileUrl}
                download
                className="file-link"
                onClick={() => handleFileDownload(file.fileName)}
              >
                {file.fileName}
              </a>
            ))}
          </div>
        ) : (
          <p>No files available</p>
        )}

        {/* Download All Button */}
        {pressKitData.files && pressKitData.files.length > 0 && (
          <a
            href="/download-all"
            className="download-all-button"
            onClick={() => trackEvent('File Download', 'Download', 'Download All Files')}
          >
            Download all
          </a>
        )}
      </section>
    </>
  );
};

export default PressKit;
