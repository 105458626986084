import React, { useState, useEffect, useCallback } from 'react';
import '../LandingSectionStyles.css';
import sanityClient from '../sanityClient';
import { Helmet } from 'react-helmet-async';
import { initializeAnalytics, trackEvent } from '../analytics'; // Import analytics utilities

const LandingSection = ({ isEnglish }) => {
  const [slides, setSlides] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slideDuration, setSlideDuration] = useState(5000); // Default duration

  useEffect(() => {
    // Initialize Google Analytics
    initializeAnalytics('G-M8CTMCVQ5P');

    // Fetch the slides data from Sanity
    sanityClient
      .fetch(
        `*[_type == "landingSlide"]{
          title,
          title_jp,
          description,
          description_jp,
          buttonText,
          buttonText_jp,
          buttonLink,
          image{
            asset->{
              url
            }
          },
          ctaEnabled,
          slideDuration
        }`
      )
      .then((data) => {
        setSlides(data);
        if (data.length > 0 && data[0].slideDuration) {
          setSlideDuration(data[0].slideDuration);
        }
      })
      .catch((err) => console.error("Error fetching slides:", err));
  }, []);

  const nextSlide = useCallback(() => {
    const nextIndex = (currentIndex + 1) % slides.length;
    setCurrentIndex(nextIndex);

    // Track manual slide navigation
    trackEvent('Slide Navigation', 'Click', `Next Slide: ${nextIndex + 1}`);
  }, [currentIndex, slides.length]);

  const prevSlide = useCallback(() => {
    const prevIndex = (currentIndex - 1 + slides.length) % slides.length;
    setCurrentIndex(prevIndex);

    // Track manual slide navigation
    trackEvent('Slide Navigation', 'Click', `Previous Slide: ${prevIndex + 1}`);
  }, [currentIndex, slides.length]);

  useEffect(() => {
    // Automatically change slides based on the slideDuration
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, slideDuration);

    return () => clearInterval(interval);
  }, [slideDuration, slides.length]);

  if (!slides.length) return <div></div>;

  return (
    <>
      <Helmet>
        <title>Welcome to Myosuki</title>
        <meta name="description" content="Explore our landing page with the latest updates from Myosuki." />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <section className="landing-section">
        <div className="slideshow-container">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`slide ${index === currentIndex ? 'active' : 'inactive'}`}
              style={{ transform: `translateX(${(index - currentIndex) * 100}%)` }}
            >
              {slide.image?.asset?.url && (
                <img src={slide.image.asset.url} alt={`Slide ${index + 1}`} className="slide-image" />
              )}

              <div className="slide-content">
                <h1>{isEnglish ? slide.title : slide.title_jp}</h1>
                <p>{isEnglish ? slide.description : slide.description_jp}</p>

                {slide.ctaEnabled && (
                  <a href={slide.buttonLink} className="cta-button">
                    {isEnglish ? slide.buttonText : slide.buttonText_jp}
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>

        <button className="prev" onClick={prevSlide}>
          &#10094;
        </button>
        <button className="next" onClick={nextSlide}>
          &#10095;
        </button>
      </section>
    </>
  );
};

export default LandingSection;
