import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../styles.css';
import { Helmet } from 'react-helmet-async';
import sanityClient from '../sanityClient';
import languageEnIcon from '../assets/language-en.png';
import languageJpIcon from '../assets/language-jp.png';
import logo from '../assets/myosuki-logo.png';
import lightModeIcon from '../assets/icon_light.png';
import darkModeIcon from '../assets/icon_dark.png';
import { initializeAnalytics, trackEvent } from '../analytics';

const Header = ({ isEnglish, setIsEnglish, isMenuOpen, setIsMenuOpen }) => {
  const [headerContent, setHeaderContent] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isGamesDropdownOpen, setIsGamesDropdownOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Reset dropdown and menu state on route changes.
   */
  useEffect(() => {
    setIsMenuOpen(false);
    setIsGamesDropdownOpen(false);
  }, [location, setIsMenuOpen]);

  /**
   * Fetch header text and initialize analytics.
   */
  useEffect(() => {
    initializeAnalytics('G-M8CTMCVQ5P');
    sanityClient
      .fetch(`*[_type == "headerText"][0]{ english, japanese }`)
      .then(setHeaderContent)
      .catch(console.error);
  }, []);

  /**
   * Initialize dark mode state from localStorage.
   */
  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(savedDarkMode);
    document.body.classList.toggle('dark-mode', savedDarkMode);
  }, []);

  /**
   * Toggle language and track the event.
   */
  const toggleLanguage = () => {
    setIsEnglish((prev) => {
      const newLanguage = !prev;
      trackEvent('Language Switch', 'Click', newLanguage ? 'English' : 'Japanese');
      return newLanguage;
    });
  };

  /**
   * Toggle dark mode and track the event.
   */
  const toggleDarkMode = () => {
    setIsDarkMode((prev) => {
      const newDarkMode = !prev;
      localStorage.setItem('darkMode', newDarkMode);
      document.body.classList.toggle('dark-mode', newDarkMode);
      trackEvent('Dark Mode Switch', 'Click', newDarkMode ? 'Dark Mode' : 'Light Mode');
      return newDarkMode;
    });
  };

  /**
   * Handle navigation link clicks.
   */
  const handlePageClick = (page) => {
    trackEvent('Page Navigation', 'Click', page);
    setIsMenuOpen(false);
  };

  /**
   * Toggle the hamburger menu state.
   */
  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  /**
   * Handle games click:
   * - On mobile: directly navigate to /games
   * - On desktop: 
   *   - If dropdown is closed, open it
   *   - If dropdown is open, navigate to /games
   */
  const handleGamesClick = () => {
    const isMobile = window.innerWidth <= 768;
    if (isMobile) {
      navigate('/games');
      handlePageClick('Games');
    } else {
      if (isGamesDropdownOpen) {
        // If already open, go to /games on second click
        navigate('/games');
        handlePageClick('Games');
      } else {
        // First click: open the dropdown
        setIsGamesDropdownOpen(true);
      }
    }
  };

  // Loading state if header content not ready
  if (!headerContent) {
    return <div>Loading...</div>;
  }

  const text = isEnglish ? headerContent.english : headerContent.japanese;

  return (
    <>
      <Helmet>
        <title>Myosuki - Home</title>
        <meta name="description" content="Welcome to Myosuki. Explore our content, news, and team." />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <header className={`header ${isMenuOpen ? 'fixed-header' : ''}`}>
        <div className="container">
          <Link to="/" className="logo" onClick={() => handlePageClick('Home')}>
            <img src={logo} alt="Myosuki Logo" className="logo-image" />
          </Link>

          <nav className={`nav ${isMenuOpen ? 'open' : ''}`}>
            {text.shop && (
              <a
                href={text.shop}
                className="nav-item"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => handlePageClick('Shop')}
              >
                {isEnglish ? 'Shop' : 'ショップ'}
              </a>
            )}
            {/* Games Dropdown */}
            <div
              className={`nav-item games-dropdown ${isGamesDropdownOpen ? 'active' : ''}`}
              onClick={handleGamesClick}
              style={{ cursor: 'pointer' }} // Ensure cursor changes to pointer
            >
              <span>{text.games}</span>
              {isGamesDropdownOpen && (
                <ul className="dropdown-menu" style={{ listStyle: 'none', paddingLeft: 0 }}>
                  {text.gamesDropdown &&
                    text.gamesDropdown.map((item, index) =>
                      item.isVisible && (
                        <li key={index} style={{ listStyle: 'none', padding: '5px 0' }}>
                          <a
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => handlePageClick(item.title)}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px',
                              textDecoration: 'none',
                              color: 'inherit'
                            }}
                          >
                            {/* If item.icon exists, display it next to the title */}
                            {item.icon && (
                              <img
                                src={item.icon}
                                alt={item.title}
                                style={{ width: '20px', height: '20px' }}
                              />
                            )}
                            {item.title}
                          </a>
                        </li>
                      )
                    )}
                  <li style={{ listStyle: 'none', padding: '5px 0' }}>
                    <span
                      onClick={() => {
                        navigate('/games');
                        handlePageClick('Games');
                      }}
                      style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
                    >
                      All Games
                    </span>
                  </li>
                </ul>
              )}
            </div>
            <Link to="/news" className="nav-item" onClick={() => handlePageClick('News')}>
              {text.news}
            </Link>
            <Link to="/about" className="nav-item" onClick={() => handlePageClick('About')}>
              {text.about}
            </Link>
            <Link to="/contact" className="nav-item" onClick={() => handlePageClick('Contact')}>
              {text.contact}
            </Link>
            <Link to="/press-kit" className="nav-item" onClick={() => handlePageClick('Press Kit')}>
              {text.pressKit}
            </Link>
            <Link to="/policy" className="nav-item" onClick={() => handlePageClick('Policy')}>
              {text.policy}
            </Link>
          </nav>

          <div className="icon-group">
            <div className="language-toggle" onClick={toggleLanguage}>
              <img
                src={isEnglish ? languageJpIcon : languageEnIcon}
                alt={isEnglish ? 'Switch to Japanese' : 'Switch to English'}
                className="language-icon"
              />
            </div>
            <button className="dark-mode-toggle" onClick={toggleDarkMode}>
              <img
                src={isDarkMode ? darkModeIcon : lightModeIcon}
                alt={isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'}
                className="dark-mode-icon"
              />
            </button>
            {/* Hamburger Menu inside icon-group */}
            <button className="hamburger-menu" onClick={toggleMenu}>
              {isMenuOpen ? (
                <svg width="24" height="24" viewBox="0 0 24 24">
                  <path d="M6 6L18 18M6 18L18 6" stroke="#fff" strokeWidth="2" />
                </svg>
              ) : (
                <svg width="24" height="24" viewBox="0 0 24 24">
                  <path d="M3 6h18M3 12h18M3 18h18" stroke="#fff" strokeWidth="2" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
